import axios from 'axios';

const getToken = async () => {
  try {
    const response = await axios.get('https://medsovetnik.net/api/mark', {
      headers: {
        'Accept-Language': 'en-US,en;q=0.8',
        Host: 'headers.jsontest.com',
        'Accept-Charset': 'ISO-8859-1,utf-8;q=0.7,*;q=0.3',
        Accept:
          'text/html,application/xhtml+xml,application/xml;q=0.9,*/*;q=0.8',
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

export default getToken;
