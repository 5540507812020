import axios from 'axios';

const sendingFile = async (files, token) => {
  const formData = new FormData();

  files.forEach((file) => {
    const fileNameWithToken = `${file.name.replace(
      /\.(pdf)$/,
      `_${token}.$1`
    )}`;
    const blob = new Blob([file], { type: file.type });
    formData.append('files', blob, fileNameWithToken);
  });

  try {
    const response = await axios.post(
      `https://medsovetnik.net/api/upload?h=${token}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error uploading files:', error);
    throw new Error('Error uploading files');
  }
};

export default sendingFile;
