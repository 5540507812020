import React, { useEffect, useState } from 'react';
import {
  Tabs,
  Tab,
  Box,
  Typography,
  Button,
  IconButton,
  Snackbar,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useDropzone } from 'react-dropzone';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';
import MuiAlert from '@mui/material/Alert';
import * as Styled from './styled';
import SendData from '../../services/sendingFile.service';
import GetToken from '../../services/getToken.service';
import axios from 'axios';

const Main = () => {
  const [token, setToken] = useState('');
  const [activeTab, setActiveTab] = useState(1);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploaded, setIsUploaded] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAnswer, setIsLoadingAnswer] = useState(false);
  const [answer, setAnswer] = useState('');
  const [height, setHeight] = useState('');
  const [weight, setWeight] = useState('');
  const [age, setAge] = useState('');
  const [gender, setGender] = useState('');

  const getAiAnswer = async (token) => {
    const formData = new FormData();
    formData.append('weight', weight);
    formData.append('height', height);
    formData.append('age', age);
    formData.append('gender', gender);
    
    try {
      const response = await axios.post(
        `https://medsovetnik.net/api/conclusion?h=${token}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      setSnackbarMessage('В вашем файле слишком мало информации !');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  useEffect(() => {
    const setValues = async () => {
      try {
        const token = await GetToken();
        setToken(token);
      } catch (error) {
        setSnackbarSeverity('error');
      }
    };
    setValues();
  }, []);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const onDrop = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const validFiles = acceptedFiles.filter(
        (file) =>
          file.type === 'application/pdf' ||
          file.type.startsWith('image/')
      );
  
      if (validFiles.length > 0) {
        setSelectedFiles((prevFiles) => [...prevFiles, ...validFiles]);
        setUploadProgress(0);
        setIsUploaded(false);
      } else {
        setSnackbarMessage('Пожалуйста, выберите файлы в формате PDF или загрузите фото.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    }
  };
  

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: true,
    maxFiles: 20,
  });

  const handleInputChange = (setter) => (event) => {
    const value = event.target.value;
    if (/^\d*$/.test(value)) {
      setter(value);
    }
  };

  const handleFileUpload = async () => {
    if (!selectedFiles || selectedFiles.length === 0) return;

    if (height > 230) {
      setSnackbarMessage('Рост не может превышать 230 см.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }
    if (weight > 300) {
      setSnackbarMessage('Вес не может превышать 300 кг.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }
    if (age > 105) {
      setSnackbarMessage('Возраст не может превышать 105 лет.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    setIsLoading(true);

    try {
      await SendData(selectedFiles, token);

      const send = async () => {
        setIsUploaded(true);
        setSnackbarMessage('Файл успешно загружен!');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        setIsLoading(false);

        setIsLoadingAnswer(true);
        const answer = await getAiAnswer(token);
        setAnswer(answer);
        setIsLoadingAnswer(false);
      };
      send();
    } catch (error) {
      setSnackbarMessage(
        'Ошибка при загрузке файла. Пожалуйста, попробуйте снова.'
      );
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      console.error('Error uploading file:', error);
      setIsLoading(false);
    }
  };

  const resetUpload = () => {
    setWeight('');
    setAnswer('');
    setHeight('');
    setAge('');
    setGender('');
    setSelectedFiles([]);
    setIsUploaded(false);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleRemoveFile = (fileToRemove) => {
    setSelectedFiles((prevFiles) =>
      prevFiles.filter((file) => file !== fileToRemove)
    );
  };

  return (
    <Styled.Main>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          aria-label="file upload tabs"
        >
          <Tab label="О сервисе" />
          <Tab label="Получить мед. анализ от AI" />
        </Tabs>
      </Box>

      {activeTab === 0 && (
        <Box sx={{ p: { xs: 2, sm: 3, md: 4 } }}>
          <Typography
            variant="h3"
            textAlign="center"
            mb={{ xs: 4, sm: 6, md: 8 }}
            sx={{ fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' } }}
          >
            Сервис предварительного диагноза <br />
            “Med sovetnik”
          </Typography>
          <Typography
            variant="h4"
            mb={{ xs: 2, sm: 3 }}
            sx={{ fontSize: { xs: '1.25rem', sm: '1.5rem', md: '1.75rem' } }}
          >
            Кто мы такие?
          </Typography>
          <Typography
            variant="h5"
            mb={{ xs: 4, sm: 6 }}
            sx={{ fontSize: { xs: '1rem', sm: '1.25rem', md: '1.5rem' } }}
          >
            Сделали этот сайт для быстрого и комфортного получение диагноза на основе ваших анализов
          </Typography>
          <Typography
            variant="h4"
            mb={{ xs: 2, sm: 3 }}
            sx={{ fontSize: { xs: '1.25rem', sm: '1.5rem', md: '1.75rem' } }}
          >
            Как именно наш сайт поможет вам в жизни?
          </Typography>
          <Typography
            variant="h5"
            sx={{ fontSize: { xs: '1rem', sm: '1.25rem', md: '1.5rem' } }}
          >
            Врачи везде разные и далеко не каждый объяснит вам от и до про ваши анализы. Зачастую мы с вами сами лезем в интернет и ищем ответы на эти вопросы. 
            Наш сервис это прежде всего ваш помощник, который позволит загрузить анализы в пдф формате, учтет ваш рост, вес, возраст, пол и на основе всех данных ИИ поставит вам предварительный диагноз и посоветуют конкретных врачей. 
            (Нейросеть не является сертифицированным специалистом и не может оказать профессиональную помощь)
          </Typography>
        </Box>
      )}
      {activeTab === 1 && (
        <Box sx={{ p: 3 }}>
          {!isUploaded ? (
            <>
              <Typography variant="h6">Загрузка файлов PDF</Typography>
              <TextField
                label="Рост (см)"
                variant="outlined"
                fullWidth
                margin="normal"
                value={height}
                onChange={handleInputChange(setHeight)}
              />
              <TextField
                label="Вес (кг)"
                variant="outlined"
                fullWidth
                margin="normal"
                value={weight}
                onChange={handleInputChange(setWeight)}
              />
              <TextField
                label="Возраст"
                variant="outlined"
                fullWidth
                margin="normal"
                value={age}
                onChange={handleInputChange(setAge)}
              />
              <FormControl fullWidth margin="normal">
                <InputLabel>Пол</InputLabel>
                <Select
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                >
                  <MenuItem value="Мужской">Мужской</MenuItem>
                  <MenuItem value="Женский">Женский</MenuItem>
                </Select>
              </FormControl>
              <Box
                {...getRootProps()}
                sx={{
                  border: '2px dashed',
                  borderColor: 'primary.main',
                  borderRadius: 2,
                  padding: 4,
                  textAlign: 'center',
                  bgcolor: 'background.paper',
                  cursor: 'pointer',
                  marginY: 2,
                }}
              >
                <input {...getInputProps()} />
                <CloudUploadIcon sx={{ fontSize: 48, color: 'primary.main' }} />
                <Typography variant="body1">
                  Перетащите сюда файл или нажмите для выбора
                </Typography>
              </Box>

              {selectedFiles.length > 0 && (
                <Box sx={{ mt: 2 }}>
                  <Typography variant="body2">Выбранные файлы:</Typography>
                  {selectedFiles.map((file, index) => (
                    <Box
                      key={index}
                      sx={{ display: 'flex', alignItems: 'center', mb: 1 }}
                    >
                      <Typography variant="body2" sx={{ flexGrow: 1 }}>
                        {file.name}
                      </Typography>
                      <IconButton onClick={() => handleRemoveFile(file)}>
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  ))}
                </Box>
              )}

              <Button
                variant="contained"
                color="primary"
                onClick={handleFileUpload}
                disabled={
                  !selectedFiles.length > 0 ||
                  uploadProgress > 1 ||
                  !height ||
                  !weight ||
                  !age ||
                  !gender ||
                  isLoading
                }
                startIcon={
                  isLoading ? (
                    <CircularProgress size={24} />
                  ) : (
                    <CloudUploadIcon />
                  )
                }
              >
                {isLoading ? 'Загрузка...' : 'Отправить Данные'}
              </Button>
            </>
          ) : (
            <>
              <Box sx={{ textAlign: 'center' }}>
                <IconButton onClick={resetUpload}>
                  <ArrowBackIcon />
                </IconButton>
              </Box>
              <Box sx={{ textAlign: 'left' }}>
                {isLoadingAnswer ? (
                  <Box sx={{ textAlign: 'center' }} mt={12}>
                    <CircularProgress size={100} />
                  </Box>
                ) : (
                  <>
                    {answer ? (
                      <>
                        <Typography variant="h5" sx={{ my: 2 }}>
                          Ответ от нейросети
                        </Typography>
                        <Typography
                          variant="body0"
                          dangerouslySetInnerHTML={{ __html: answer }}
                        />
                      </>
                    ) : (
                      <Box textAlign={'center'}>
                        <Typography variant="h5" sx={{ my: 2 }}>
                          Ошибка получения заключения ИИ.
                        </Typography>
                      </Box>
                    )}
                  </>
                )}
              </Box>
            </>
          )}
        </Box>
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <MuiAlert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </Styled.Main>
  );
};

export default Main;
