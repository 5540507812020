import React from 'react';
import { Outlet } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import {
  Box,
  Container,
  CssBaseline,
  AppBar,
  Toolbar,
  Typography,
} from '@mui/material';

// Create a light theme
const lightTheme = createTheme({
  palette: {
    mode: 'light',
  },
});

const Layout = () => {
  return (
    <ThemeProvider theme={lightTheme}>
      <Box
        sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}
      >
        <CssBaseline />
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6" component="div">
              Med Sovetnik
            </Typography>
          </Toolbar>
        </AppBar>
        <Container
          component="main"
          sx={{
            flexGrow: 1,
            bgcolor: 'background.default',
            overflow: 'hidden',
            minHeight: '100vh',
          }}
        >
          <Outlet />
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default Layout;
